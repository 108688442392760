import { useState, useContext, useEffect } from 'react';
import AsadaContext from '../context/asada/asadaContext';
import ThemeBoxes from '../components/shared/ThemeBoxes';
import baseURL from '../utils/baseUrl';
import { toast } from 'react-toastify';
import themes from '../../src/themes.json';

const ThemeSelectPage = () => {
  const { asada } = useContext(AsadaContext);
  const [selectedTheme, setSelectedTheme] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClick = async () => {
    setIsSubmitting(true);

    const currentTheme = document
      .querySelector('html')
      .getAttribute('data-theme');
    setSelectedTheme(currentTheme);

    try {
      const idAsada = asada.id;

      const response = await fetch(
        `${baseURL}/api/v1/asadas/${idAsada}/colorscheme`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ esquema_colores: currentTheme }),
        }
      );

      if (!response.ok) {
        throw new Error(
          `Error al guardar el esquema de colores: ${response.statusText}`
        );
      }

      toast.success('Esquema de colores guardado con éxito');
      console.log('Esquema de colores guardado con éxito', currentTheme);
    } catch (error) {
      toast.error('Error al guardar el esquema de colores');
      console.error('Error al guardar el esquema de colores:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    // Guardar el tema actual antes de cambiarlo
    const previousTheme = localStorage.getItem('theme') || 'defecto';

    return () => {
      // Restaurar el tema anterior cuando el componente se desmonte
      document.querySelector('html').setAttribute('data-theme', previousTheme);
    };
  }, []);

  return (
    <>
      <h3 className="text-sm mb-5">
        Seleccione el esquema de colores de la aplicación
      </h3>
      <ThemeBoxes themes={themes} />
      <button
        className={`btn btn-primary hover:btn-secondary max-w-xs mt-5 ${
          isSubmitting ? 'loading loading-ring loading-sm' : ''
        }`}
        onClick={handleClick}
        disabled={isSubmitting}
      >
        {isSubmitting ? 'Guardando...' : 'Guardar esquema'}
      </button>
    </>
  );
};

export default ThemeSelectPage;
