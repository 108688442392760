const validateLength = (field, fieldName, min, max, errors) => {
  if (!field || typeof field !== 'string') {
    console.log(`Error: El campo ${fieldName} es inválido o no es una cadena`);
    return;
  }

  if (field.length < min || field.length > max) {
    errors.push(
      `El valor de '${fieldName}' debe tener entre ${min} y ${max} caracteres.`
    );
  }
};

const validateDoubleFormat = (field, fieldName, errors) => {
  if (field === undefined || field === null) {
    console.log(`Error: ${fieldName} es undefined o null`);
    return;
  }

  if (field.toString().replace('.', '').length > 12 || field > 9999999999.99) {
    errors.push(
      `El valor de '${fieldName}' no puede exceder 10 dígitos antes del punto decimal y 2 dígitos después.`
    );
  }
};

const validateImageExtension = (field, fieldName, errors) => {
  if (field === undefined || field === null) {
    errors.push(`Error: ${fieldName} es undefined o null`);
    console.log(`Error: ${fieldName} es undefined o null`);
    return;
  }

  // If field is empty string then return
  if (field === '') return;

  const files = Array.isArray(field) ? field : [field];

  files.forEach((file) => {
    const imageExtension = file.name.split('.').pop().toLowerCase();

    if (
      imageExtension === 'jpg' ||
      imageExtension === 'jpeg' ||
      imageExtension === 'png'
    ) {
      console.log(`${file.name} tiene una extensión válida: ${imageExtension}`);
    } else {
      errors.push(
        `Error: ${file.name} debe ser una imagen en formato jpg, jpeg, o png.`
      );
      console.log(
        `Error: ${file.name} tiene una extensión inválida: ${imageExtension}`
      );
    }
  });
};

export { validateLength, validateDoubleFormat, validateImageExtension };
