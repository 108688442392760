import { useEffect, useState, useContext } from "react";
import asadaContext from "../../context/asada/asadaContext";
import Modal from "../shared/Modal";
import LoginContext from "../../context/login/LoginContext";

function GeneralInfo() {
  const { asada, updateAsada, isLoading, getSingleAsada } = useContext(asadaContext);
  const { user } = useContext(LoginContext);

  const [btnDisabled, setBtnDisabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = "auto";
  };

  const {
    mision,
    vision,
    horario,
    extension,
    direccion,
    usuarios,
    tanques,
    gradientes,
    texto_proyectos,
    texto_metodos_pago,
    slogan,
    historia,
  } = asada;

  const [form, setForm] = useState({});

  useEffect(() => {
    const fetchSingleAsada = async (asadaId) => {
      await getSingleAsada(asadaId);
    };
    fetchSingleAsada(user.asadaId);
  }, []);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await updateAsada(form);
    setTimeout(async () => {
      await getSingleAsada(asada.id);
    }, 500);
  };

  return (
    <div className="text-lg">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl my-4 font-bold card-title">
          Información General{' '}
          <button
            type="button"
            className="btn btn-ghost ml-2"
            onClick={openModal}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
              />
            </svg>
          </button>
        </h2>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="flex w-full justify-center">
          <div className="flex-1 w-1/2">
            <div className="w-full px-3">
              <label
                className="block  tracking-wide  text-xs font-bold mb-2"
                htmlFor="grid-slogan"
              >
                Slogan
              </label>
              <textarea
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-slogan"
                type="text"
                name="slogan"
                placeholder="Slogan de la ASADA"
                defaultValue={slogan}
                onChange={handleChange}
              />
            </div>
            <div className="w-full px-3">
              <label
                className="block  tracking-wide  text-xs font-bold mb-2"
                htmlFor="grid-mision"
              >
                Misión
              </label>
              <textarea
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-mision"
                type="text"
                name="mision"
                placeholder="Misión de la ASADA"
                defaultValue={mision}
                onChange={handleChange}
              />
            </div>
            <div className="w-full px-3">
              <label
                className="block  tracking-wide  text-xs font-bold mb-2"
                htmlFor="grid-vision"
              >
                Visión
              </label>
              <textarea
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-vision"
                type="text"
                name="vision"
                placeholder="Visión de la ASADA"
                defaultValue={vision}
                onChange={handleChange}
              />
            </div>
            <div className="w-full px-3">
              <label
                className="block  tracking-wide  text-xs font-bold mb-2"
                htmlFor="grid-historia"
              >
                Historia
              </label>
              <textarea
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-historia"
                type="text"
                name="historia"
                placeholder="Historia de la ASADA"
                defaultValue={historia}
                onChange={handleChange}
              />
            </div>
            <div className="w-full px-3">
              <label
                className="block  tracking-wide  text-xs font-bold mb-2"
                htmlFor="grid-direccion"
              >
                Dirección
              </label>
              <textarea
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-direccion"
                type="text"
                name="direccion"
                placeholder="Dirección de la ASADA"
                defaultValue={direccion}
                onChange={handleChange}
              />
            </div>
            <div className="w-full px-3">
              <label
                className="block  tracking-wide  text-xs font-bold mb-2"
                htmlFor="grid-horario"
              >
                Horario de Atención
              </label>
              <textarea
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-horario"
                type="text"
                name="horario"
                placeholder="Horario de la ASADA"
                defaultValue={horario}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="flex-1 w-1/2">
            <div className="w-full px-3">
              <label
                className="block  tracking-wide  text-xs font-bold mb-2"
                htmlFor="grid-textoProyectos"
              >
                Texto para proyectos
              </label>
              <textarea
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-textoProyectos"
                type="text"
                name="texto_proyectos"
                placeholder="Este texto se mostrará en la página de Inicio, en la sección de Proyectos"
                defaultValue={texto_proyectos}
                onChange={handleChange}
              />
            </div>
            <div className="w-full px-3">
              <label
                className="block  tracking-wide  text-xs font-bold mb-2"
                htmlFor="grid-textoMetodosPago"
              >
                Texto para métodos de pago
              </label>
              <textarea
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-textoMetodosPago"
                type="text"
                name="texto_metodos_pago"
                placeholder="Este texto se mostrará en la página de Inicio, en la sección de Métodos de Pago"
                defaultValue={texto_metodos_pago}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-wrap w-full">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block  tracking-wide text-xs font-bold mb-2"
                  htmlFor="grid-users"
                >
                  Usuarios abastecidos
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-users"
                  type="text"
                  name="usuarios"
                  placeholder="# de usuarios abastecidos"
                  defaultValue={usuarios}
                  onChange={handleChange}
                />
              </div>
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block  tracking-wide  text-xs font-bold mb-2"
                  htmlFor="grid-extension"
                >
                  Extensión
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-extension"
                  type="text"
                  name="extension"
                  placeholder="Extensión de la ASADA"
                  defaultValue={extension}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="flex flex-wrap w-full">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block  tracking-wide text-xs font-bold mb-2"
                  htmlFor="grid-tanks"
                >
                  Tanques de Almacenamiento
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="grid-tanks"
                  type="text"
                  placeholder="# de tanques "
                  name="tanques"
                  defaultValue={tanques}
                  onChange={handleChange}
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block  tracking-wide  text-xs font-bold mb-2"
                  htmlFor="grid-gradientes"
                >
                  Quiebragradientes
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-gradientes"
                  type="text"
                  placeholder="# de quiebragradientes"
                  name="gradientes"
                  defaultValue={gradientes}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
        <small className="ml-1 text-2xs text-gray-500">
          * Campos requeridos
        </small>
        <div className="divider"></div>

        <div className="card-actions justify-start">
          <button
            type="submit"
            disabled={btnDisabled}
            className={`btn btn-success hover:bg-success-content hover:text-success capitalize ${
              isLoading ? 'loading' : ''
            }`}
          >
            Guardar cambios&nbsp;
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
            </svg>
          </button>
        </div>
      </form>
      <Modal isModalOpen={isModalOpen} closeModal={closeModal}>
        <div>
          <p className="font-light text-lg">Consideraciones</p>
          <ul className="list-disc">
            <li className="font-light text-sm my-1">
              Los campos requeridos están marcados con un asterisco (*).
            </li>
            <li className="font-light text-sm my-1">
              Los campos 'Horario de Atención' y 'Dirección' se muestran en la
              página pública, en la pestaña 'Contacto'. Todos los demás campos
              se muestran en la pestaña de Inicio.
            </li>
            <li className="font-light text-sm my-1">
              Los campos 'Usuarios abastecidos', 'Tanques de Almacenamiento' y
              'Quiebragradientes' deben ser números enteros mayores a 0.
            </li>
          </ul>
        </div>
      </Modal>
    </div>
  );
}

export default GeneralInfo;
