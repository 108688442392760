import { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import TanksContext from '../../context/tanks/TanksContext';
import LoginContext from '../../context/login/LoginContext';
import ConfigContext from '../../context/configContext';
import Modal from '../shared/Modal';

import { toast } from 'react-toastify';
import { validateDoubleFormat, validateLength } from '../../utils/utils';

function setInputFilter(textbox, inputFilter) {
  [
    'input',
    'keydown',
    'keyup',
    'mousedown',
    'mouseup',
    'select',
    'contextmenu',
    'drop',
  ].forEach(function (event) {
    textbox.addEventListener(event, function () {
      if (inputFilter(this.value)) {
        this.oldValue = this.value;
        this.oldSelectionStart = this.selectionStart;
        this.oldSelectionEnd = this.selectionEnd;
      } else if (this.hasOwnProperty('oldValue')) {
        this.value = this.oldValue;
        this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
      } else {
        this.value = '';
      }
    });
  });
}

function TankAdd() {
  const { isLoading, setIsLoading, getAllTanques, createTanque } =
    useContext(TanksContext);
  const { user } = useContext(LoginContext);
  const { config, getConfigByAsadaId } = useContext(ConfigContext);

  const navigate = useNavigate();
  const locationReact = useLocation();
  const [name, setName] = useState('');
  const [location, setLocation] = useState('');
  const [capacity, setCapacity] = useState('');
  const [photos, setPhotos] = useState('');
  const [costo, setCosto] = useState(0);
  const [proveedor, setProveedor] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [unidadMedida, setUnidadMedida] = useState('');

  const queryParams = new URLSearchParams(locationReact.search);
  const aid = queryParams.get('aid');

  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = 'auto';
  };

  useEffect(() => {
    setInputFilter(document.getElementById('grid-capacity'), function (value) {
      return /^\d*$/.test(value);
    });
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const asadaId = aid || process.env.REACT_APP_ASADA_ID;

        if (!asadaId) {
          console.warn('No se encontró "aid" ni variable de entorno');
          return;
        }

        await getConfigByAsadaId(asadaId);
      } catch (error) {
        console.error('Error fetching config:', error);
      }
    };

    fetchConfig();
  }, [aid, getConfigByAsadaId]);

  useEffect(() => {
    if (config && config.unidad_medida) {
      setUnidadMedida(config.unidad_medida);
    }
  }, [config]);

  const formReset = () => {
    setName('');
    setLocation('');
    setCapacity('');
    setCosto('');
    setProveedor('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateForm();

    if (errors.length > 0) {
      errors.forEach((e) => {
        toast.error(e, { autoClose: false });
      });
      return;
    }

    const fd = new FormData();
    fd.set('nombre', name);
    fd.set('ubicacion', location);
    fd.set('capacidad', capacity);
    fd.set('costo', costo);
    fd.set('proveedor', proveedor);

    photos.forEach((photo) => fd.append('images', photo));

    if (aid) {
      await createTanque(fd, aid);
      await getAllTanques(process.env.REACT_APP_ASADA_ID);
    } else {
      createTanque(fd, user.asadaId);
      await getAllTanques(process.env.REACT_APP_ASADA_ID);
    }
    formReset();
  };

  const validateForm = () => {
    let errors = [];

    // Validar longitud de texto para cada campo
    validateLength(name, 'nombre', 3, 100, errors);
    validateLength(location, 'ubicación', 3, 100, errors);
    validateLength(proveedor, 'proveedor', 3, 100, errors);

    // Validar formato DOUBLE(12, 2) para capacidad y costo
    validateDoubleFormat(capacity, 'capacidad', errors);
    validateDoubleFormat(costo, 'costo', errors);

    return errors;
  };

  return (
    <div className="rounded-lg shadow-lg card bg-base-100">
      <div className="card-body">
        <h2 className="text-2xl my-4 font-bold card-title">
          <button
            onClick={() => navigate(-1)}
            className="btn btn-outline btn-accent btn-sm hover:text-white"
            disabled={isLoading}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 "
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          Agregar nuevo tanque de almacenamiento
        </h2>
        <div className="flex justify-center text-lg">
          <form className="w-full max-w-lg" onSubmit={handleSubmit}>
            <div className="w-full px-3">
              <label
                className="block tracking-wide text-xs font-bold mb-2"
                htmlFor="grid-nombre"
              >
                Nombre *
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-nombre"
                type="text"
                required
                name="nombre"
                placeholder="Nombre del tanque"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="w-full px-3">
              <label
                className="block tracking-wide text-xs font-bold mb-2"
                htmlFor="grid-ubicacion"
              >
                Ubicación *
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-ubicacion"
                type="text"
                required
                name="location"
                placeholder="Ubicación del tanque"
                onChange={(e) => setLocation(e.target.value)}
              />
            </div>
            <div className="w-full px-3">
              <label
                className="block  tracking-wide text-xs font-bold mb-2"
                htmlFor="grid-capacity"
              >
                Capacidad en{' '}
                {unidadMedida === 'l/s'
                  ? 'litros por segundo (l/s)'
                  : 'metros cúbicos (m³)'}{' '}
                *
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-capacity"
                type="text"
                required
                name="capacity"
                placeholder={`Ingresar ${unidadMedida} (Ej: 325)`}
                onChange={(e) => setCapacity(e.target.value)}
              />
            </div>
            <div className="w-full px-3">
              <label
                className="block  tracking-wide text-xs font-bold mb-2"
                htmlFor="grid-costo"
              >
                Costo *
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-costo"
                type="text"
                required
                name="proveedor"
                placeholder="Ingresar costo en colones"
                onChange={(e) => setCosto(e.target.value)}
              />
            </div>
            <div className="w-full px-3">
              <label
                className="block  tracking-wide text-xs font-bold mb-2"
                htmlFor="grid-proveedor"
              >
                Proveedor *
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-proveedor"
                type="text"
                required
                name=" proveedor"
                placeholder="Ingresar proveedor"
                onChange={(e) => setProveedor(e.target.value)}
              />
            </div>
            <div className="w-full px-3">
              <label
                className="block  tracking-wide text-xs font-bold mb-2"
                htmlFor="grid-photos"
              >
                Fotos *
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-photos"
                type="file"
                multiple
                lang="es"
                name="photos"
                onChange={(e) => setPhotos(Array.from(e.target.files))}
              />
              <small className="ml-1 text-2xs text-gray-500">
                Formato permitido: .jpg, .jpeg, .png
              </small>{' '}
              <br />
              <small className="ml-1 text-2xs text-gray-500">
                * Campos requeridos
              </small>
            </div>

            <div className="divider"></div>

            <div className="card-actions justify-start">
              <button
                type="submit"
                className={`btn btn-success hover:bg-green-700 capitalize ${
                  isLoading ? 'loading' : ''
                }`}
              >
                {!isLoading ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
                  </svg>
                ) : (
                  <></>
                )}
                &nbsp;Agregar
              </button>
              <button
                type="button"
                className="btn btn-ghost ml-2 justify-self-end"
                onClick={openModal}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                  />
                </svg>
              </button>
            </div>
          </form>
        </div>
      </div>
      <Modal isModalOpen={isModalOpen} closeModal={closeModal}>
        <div>
          <p className="font-light text-lg">Consideraciones</p>
          <ul className="list-disc">
            <li className="font-light text-sm my-1">
              Los campos requeridos están marcados con un asterisco (*).
            </li>
            <li className="font-light text-sm my-1">
              La ubicación debe tener más de 3 y menos de 100 caracteres.
            </li>
            <li className="font-light text-sm my-1">
              La capacidad y el costo deben ser números enteros positivos.
            </li>
            <li className="font-light text-sm my-1">
              El proveedor debe tener más de 3 y menos de 100 caracteres.
            </li>
            <li className="font-light text-sm my-1">
              Las fotos deben ser en formato png, jpg o jpeg.
            </li>
            <li className="font-light text-sm my-1">
              Cada foto no puede pesar más de 3MB.
            </li>
            <li className="font-light text-sm my-1">
              Para agregar más de una foto mantenga presionada la tecla{' '}
              <kbd className="px-1">Ctrl</kbd> en el teclado mientras selecciona
              varias fotos.
            </li>
          </ul>
        </div>
      </Modal>
    </div>
  );
}

export default TankAdd;
