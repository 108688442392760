import ThemeBox from './ThemeBox';

const ThemeBoxes = ({ themes }) => {
  return (
    <div className="rounded-box grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
      {themes.length === 0 ? (
        <p>No themes found</p>
      ) : (
        <>
          {themes.map((theme, index) => (
            <ThemeBox name={theme.name} text={theme.text} key={index} />
          ))}
        </>
      )}
    </div>
  );
};

export default ThemeBoxes;
