import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PostContext from "../../context/posts/PostContext";
import Modal from "../shared/Modal";
import LoginContext from "../../context/login/LoginContext";
import { validateLength, validateImageExtension } from '../../utils/utils';
import { toast } from 'react-toastify';

function PostAdd() {
  const { createPublicacion, isLoading, setIsLoading } =
    useContext(PostContext);
  const { user } = useContext(LoginContext);
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [desc, setDesc] = useState('');
  const [postType, setPostType] = useState('Anuncios');
  const [photos, setPhotos] = useState([]);
  // const [searchParams, setSearchParams] = useSearchParams();

  const titleChange = (e) => setTitle(e.target.value);
  const descChange = (e) => setDesc(e.target.value);
  const postTypeChange = (e) => setPostType(e.target.value);
  const onFilesChange = (e) => setPhotos(Array.from(e.target.files));
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = 'auto';
  };

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const formReset = () => {
    setTitle('');
    setDesc('');
    setPostType('');
    setPostType([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateForm();

    if (errors.length > 0) {
      errors.forEach((e) => {
        toast.error(e, { autoClose: false });
      });
      return;
    }

    const fd = new FormData();
    fd.set('titulo', title);
    fd.set('descripcion', desc);
    fd.set('tipo_publicacion', postType);
    photos.forEach((photo) => fd.append('images', photo));

    const searchParams = new URLSearchParams(window.location.search);
    const asadaId = searchParams.get('aid');
    if (asadaId) await createPublicacion(fd, asadaId);
    else createPublicacion(fd, user.asadaId);

    formReset();
  };

  const validateForm = () => {
    let errors = [];

    // Validar longitud de texto para cada campo
    validateLength(title, 'titulo', 3, 100, errors);
    validateLength(desc, 'descripcion', 3, 2000, errors);

    // Validar extension de la imagen
    validateImageExtension(photos, 'fotos', errors);

    return errors;
  };

  return (
    <div className="rounded-lg shadow-lg card bg-base-100">
      <div className="card-body">
        <h2 className="text-2xl my-4 font-bold card-title">
          <button
            onClick={() => navigate(-1)}
            className="btn btn-outline btn-accent btn-sm hover:text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 "
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          Agregar publicación nueva
        </h2>
        <div className="flex justify-center text-lg">
          <form className="w-full max-w-lg" onSubmit={handleSubmit}>
            <div className="w-full px-3">
              <label
                className="block tracking-wide  text-xs font-bold mb-2"
                htmlFor="grid-name"
              >
                Título *
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-title"
                type="text"
                required
                name="title"
                placeholder="Título de la publicación"
                onChange={titleChange}
              />
            </div>
            <div className="w-full px-3">
              <label
                className="block tracking-wide  text-xs font-bold mb-2"
                htmlFor="grid-fdesc"
              >
                Descripción *
              </label>
              <textarea
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-desc"
                type="text"
                required
                name="desc"
                placeholder="Descripción de la publicación"
                onChange={descChange}
              />
            </div>
            <div className="w-full px-3">
              <label
                className="block inline tracking-wide  text-xs font-bold mb-2"
                htmlFor="grid-message"
              >
                Tipo de publicación *
              </label>

              <div className="form-control">
                <select
                  className="select select-md select-accent w-full max-w-xs mb-2"
                  onChange={postTypeChange}
                  defaultValue="0"
                >
                  <option value="0" disabled>
                    -- Seleccionar --
                  </option>
                  <option value={'anuncio'} defaultValue={'anuncio'}>
                    Anuncios
                  </option>
                  <option value={'evento'}>Eventos</option>
                  <option value={'noticia'}>Noticias</option>
                  <option value={'financiero'}>Financieros</option>
                </select>
              </div>
            </div>
            <div className="w-full px-3">
              <label
                className="block tracking-wide  text-xs font-bold mb-2"
                htmlFor="grid-email"
              >
                Fotos *
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-images"
                type="file"
                multiple
                lang="es"
                name="images"
                onChange={onFilesChange}
                accept=".png, .jpg, .jpeg"
              />
              <small className="ml-1 text-2xs text-gray-500">
                Formato permitido: .png, .jpg, .jpeg
              </small>{' '}
              <br />
              <small className="ml-1 text-2xs text-gray-500">
                * Campos requeridos
              </small>
            </div>

            <div className="divider"></div>

            <div className="card-actions justify-start">
              <button
                type="submit"
                className={`btn btn-success hover:bg-green-700 capitalize ${
                  isLoading ? 'loading' : ''
                }`}
              >
                {!isLoading ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
                  </svg>
                ) : (
                  <></>
                )}
                &nbsp;Agregar
              </button>
              <button
                type="button"
                className="btn btn-ghost ml-2 justify-self-end"
                onClick={openModal}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                  />
                </svg>
              </button>
            </div>
          </form>
        </div>
      </div>
      <Modal isModalOpen={isModalOpen} closeModal={closeModal}>
        <div>
          <p className="font-light text-lg">Consideraciones</p>
          <ul className="list-disc">
            <li className="font-light text-sm my-1">
              Los campos requeridos están marcados con un asterisco (*).
            </li>
            <li className="font-light text-sm my-1">
              El título debe tener más de 3 y menos de 100 caracteres.
            </li>
            <li className="font-light text-sm my-1">
              La descripción debe tener más de 3 y menos de 2000 caracteres.
            </li>
            <li className="font-light text-sm my-1">
              Las fotos deben ser en formato png, jpg o jpeg.
            </li>
            <li className="font-light text-sm my-1">
              Cada foto no puede pesar más de 3MB.
            </li>
            <li className="font-light text-sm my-1">
              Para agregar más de una foto mantenga presionada la tecla
              <kbd className="px-1">Ctrl</kbd> en el teclado mientras selecciona
              varias fotos.
            </li>
          </ul>
        </div>
      </Modal>
    </div>
  );
}

export default PostAdd;
